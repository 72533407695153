<template>
  <div id="pdfDom" ref="pdfDom" style="padding: 20px 30px 20px 20px">
    <!-- 工具栏 -->
    <div class="toolbar">
      <el-row type="flex" align="middle">
        <el-col :span="12">志愿表名称:{{ tableName }}
          <el-button type="primary" plain size="mini" @click="changeNameDialog = true" class="margin_right">修改
          </el-button>
        </el-col>
        <el-col :span="13" >
          <div class="toobDiv">
            <el-button type="primary" plain size="mini" class="margin_right" @click="newTableDialog = true"
              icon="el-icon-circle-plus-outline">新建</el-button>
            <el-button type="primary" plain size="mini" class="margin_right" @click="copyDialog = true"
              icon="el-icon-document-copy">复制</el-button>
            <el-button type="primary" plain size="mini" class="margin_right" @click="save()"
              icon="el-icon-circle-plus-outline">保存</el-button>
            <el-button type="primary" plain size="mini" class="margin_right" @click="downReport()"
              icon="el-icon-circle-plus-outline">导出</el-button>
            <el-button type="primary" plain size="mini" class="margin_right" icon="el-icon-circle-plus-outline"
              @click="oneKeyAdd">智能填充</el-button>
            <el-button type="primary" plain size="mini" class="margin_right" @click="otherTable"
              icon="el-icon-circle-plus-outline">查看其他志愿表</el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 志愿表 -->
    <div class="table">
      <el-row type="flex" align="middle">
        <el-col :span="24">
          <table border v-for="(item, i) in reportTable" :key="i">
            <tbody>
              <tr rowspan="7" class="th">
                <td rowspan="7" style="width: 130px; background: white">
                  <div class="px">
                    平行志愿{{ word[i] }}
                  </div>
                  <div v-if="item.name == '暂无'">
                    <div class="fc">服从调剂</div>
                  </div>
                  <div v-else>
                    <div class="fc" v-if="item.tiaoji">服从调剂</div>
                    <div class="fc" v-else style="color: red">拒绝调剂</div>
                  </div>

                  <div class="hw">
                    <el-popover v-model="item.visibless" popper-class="popover-f" placement="bottom" trigger="manual">
                      <div class="popover-box">
                        <p class="box-title">填报为</p>
                        <p v-for="h in len" :key="h" class="box-item" @click="move(item, h, word[h - 1], i)">
                          平行志愿{{ word[h - 1] }}
                        </p>
                      </div>
                      <el-button slot="reference" style="margin-left: 14px" round size="mini" @click="moveEidt(item)">换位
                      </el-button>
                    </el-popover>
                  </div>
                  <div class="edit">
                    <el-button round size="mini" @click="getSpecialty(item)">编辑</el-button>
                  </div>
                  <div class="del" v-if="item.name !== '暂无'">
                    <el-button round size="mini" type="danger" plain @click="delCollege(item, i)">删除</el-button>
                  </div>
                  <div class="fc" v-else>
                    <el-button round size="mini" type="success" plain @click="toCollegeFirst">添加</el-button>
                  </div>
                </td>
                <td colspan="5">
                  <span class="name">{{ item.name }}</span>
                  <span>所在地：{{ item.city_name }} </span>
                  <span>招生代号：{{ item.school_id }} </span>
                  <span>院校计划：{{ item.plan_num }} </span>
                  <span>录取原则：{{ item.lqgz }}</span>
                </td>
              </tr>
              <tr>
                <td class="th" style="width: 100px">专业序号</td>
                <td class="th">专业代号/名称</td>
                <td class="th" style="width: 100px">专业详情</td>
                <td class="th" style="width: 100px">专业计划</td>
                <td class="th" style="width: 100px">学费标准</td>
              </tr>
              <tr v-for="(val, key) in item.specialty" :key="key">
                <td>专业{{ word[key] }}</td>
                <td>
                  [{{ val.specialty_id | interpo }}]{{ val.specialty_name }}
                </td>
                <td v-if="val.remarks">
                  <el-tooltip class="item" effect="dark" :content="val.remarks" placement="top">
                    <el-button size="mini">查看</el-button>
                  </el-tooltip>
                </td>
                <td v-else> </td>
                <td>{{ val.plan_num }}</td>
                <td>{{ val.tuition }}</td>
              </tr>

              <!-- 没有的填充一下 -->
              <tr v-for="test in 5 - item.specialty.length" :key="test + i + 'dfd'">
                <td style="height: 35px"></td>
                <td style="height: 35px"></td>
                <td style="height: 35px"></td>
                <td style="height: 35px"></td>
                <td style="height: 35px"></td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>
    </div>

    <!-- 弹窗 -->
    <el-dialog title="提示" :visible.sync="dialogVisibles" width="1200px">
      <el-table v-if="reportList" :cell-style="{ 'font-size': '16px' }" :header-cell-style="{
        'background-color': '#f4f7fc',
        'letter-spacing': '3px',
      }" :data="reportList" border>
        <el-table-column prop="table_name" align="center" label="志愿表名称" width="200" />
        <el-table-column prop="batch" align="center" label="批次" width="199">
          <template slot-scope="scope">
            <p>{{ batchList[scope.row.batch - 1].name }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="subject" align="center" label="科类" width="180">
          <template slot-scope="scope">{{
            scope.row.is_wenli == 1 ? "文科" : "理科"
          }}</template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="时间" width="200" />
        <el-table-column prop="score" align="center" label="成绩" width="180" />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button class="del" @click="edit(scope.row)" type="danger" plain size="mini">查看此方案</el-button>
            <el-button class="del" @click="del(scope.row.id)" type="danger" plain size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 志愿表名称 -->
    <el-dialog title="修改表名称" :visible.sync="changeNameDialog" width="500px" class="dialogName">
      <div class="content">
        <p>当前表名称：{{ tableName }}</p>
        <span style="vertical-align: middle">修改表名称：</span>
        <el-input style="width: 100px" size="mini" v-model="newName"></el-input>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="changeNameDialog = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="changeTableName" size="mini">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="新建志愿表" :visible.sync="newTableDialog" width="500px" class="dialogName">
      <div class="content">
        <p>使用系统成绩新建一份志愿表</p>
        <span style="vertical-align: middle">新建表名称：</span>
        <el-input style="width: 100px" size="mini" v-model="newName"></el-input>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="newTableDialog = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="setNew()" size="mini">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="复制志愿表" :visible.sync="copyDialog" width="500px" class="dialogName">
      <div class="content">
        <p>将当前志愿表的所有内容复制成一个新的志愿表，可在此基础上进行编辑</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="copyDialog = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="copy()" size="mini">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="添加志愿" :visible.sync="addDialog" width="500px" class="dialogName">
      <div class="content">
        <router-link to="/volunteer/CollegeFirst" class="choseType">
          <p style="
                  text-align: center;
                  border: 1px solid;
                  line-height: 35px;
                  width: 240px;
                  margin: 0 auto;
                ">
            选择院校优先填报
          </p>
        </router-link>
        <router-link to="/volunteer/MajorFirst" class="choseType">
          <p style="
                  text-align: center;
                  border: 1px solid;
                  line-height: 35px;
                  width: 240px;
                  margin: 20px auto 0;
                ">
            选择专业优先填报
          </p>
        </router-link>
      </div>
      <div style="height: 40px"></div>
    </el-dialog>

    <!-- dialogVisiblesss -->
    <!-- 弹出框-选择专业 -->
    <el-dialog :visible.sync="dialogVisible" width="1200px" destroy-on-close>
      <mojar-item :collegeData="collegeData" v-if="dialogVisible" :len="len" @Refresh="RefreshTable"
        @close="closeDiaLog" />
    </el-dialog>
    <tool-tip />
  </div>
</template>

<script>
import mojarItem from './components/mojar-item/index'
import ToolTip from "./components/ToolTip.vue";
import { mapState, mapMutations } from "vuex";
export default {
  name: "ReportTable",
  computed: {
    ...mapState(["userInfo", 'record', 'reportTable', 'batch', 'tableName', 'scoreLine', 'report_id']),
  },
  components: {
    mojarItem,
    ToolTip,
  },
  data() {
    return {
      table: {},
      changeNameDialog: false,
      dialogVisibles: false,
      dialogVisible: false,
      newTableDialog: false,
      copyDialog: false,
      addDialog: false,

      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九","十",'十一','十二'],
      batchList: [
        { name: "本科一批", value: 1 },
        { name: "本科二批", value: 2 },
        { name: "高职高专批", value: 3 },
      ],

      newName: '',
      reportList: [],

      collegeData: [],

      ListPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      len: 12,
    };
  },

  created() {
    this.$emit("Nav", 3);
    //判断临时表表名 如果没有新增
    if (!this.tableName) {
      console.log(this.userInfo.user_name)
      this.setTableName(this.userInfo.user_name);
    }
    if (this.reportTable == null) {
      this.setTableReportData()
    }
  },

  watch: {
    //如果批次变化。更新志愿表
    batch() {
      this.RefreshRecord();
      this.setTableReportData()
    },
  },
  methods: {
    ...mapMutations(['setTableName', 'setReportTable', 'setRecord', 'setReportId']),

    setTableReportData() {
      this.reportTables = [];
      for (let i = 0; i < this.len; i++) {
        this.reportTables.push({
          name: "暂无",
          specialty: [],
        });
      }
      this.setReportTable(this.reportTables)
    },

    RefreshRecord() {
      this.records = {
        id: [],
        dg: [],
        sp: [],
      };
      this.setRecord(this.records)
    },

    //修改表名
    changeTableName() {
      this.setTableName(this.newName);
      this.changeNameDialog = false;
    },

    oneKeyAdd() {
      let cha = this.userInfo.score - this.scoreLine[this.batch - 1];
      this.$fecth
        .post("report/report", {
          score: this.userInfo.score,
          is_wenli: this.userInfo.is_wenli,
          batch: this.batch,
          cha: cha,
        })
        .then((res) => {
          this.setRecord(JSON.parse(res.record))
          this.setReportTable(JSON.parse(res.table))
        });
    },

    //保存
    save() {
      let report_id = this.report_id;
      let data = {
        table_name: this.tableName,
        is_wenli: this.userInfo.is_wenli,
        batch: this.batch,
        table: JSON.stringify(this.reportTable),
        record: JSON.stringify(this.record),
        score: this.userInfo.score,
      };

      if (report_id) {
        data.id = report_id;
        this.$fecth
          .post("report/add", data)
          .then(() => {
            this.$message({
              type: "success",
              message: "保存成功",
            });
          })
          .catch((res) => {
            console.log(res);
          });
      } else {
        this.$fecth
          .post("report/add", data)
          .then((data) => {
            this.setReportId(data)
            this.$message({
              type: "success",
              message: "生成新志愿成功",
            });
          })
          .catch((res) => {
            console.log(res);
          });
      }
    },

    //查看其他志愿表
    otherTable() {
      this.dialogVisibles = true;
      this.getReportList();
    },

    //获取志愿表列表
    getReportList() {
      this.$fecth
        .post("user_info/getReportList", {
          pageNum: this.ListPage.pageNum,
          pageSize: this.ListPage.pageSize,
        })
        .then((res) => {
          this.reportList = res.list;
          this.total = res.count;
        });
    },

    //删除志愿表
    del(id) {
      this.$fecth
        .post("report/delReport", {
          id: id,
        })
        .then(() => {
          this.getReportList();
          this.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
          });
        });
    },

    //编辑
    edit(item) {
      this.$parent.$data.batch = String(item.batch);
      // this.setTableName(item.table_name);
      this.setReportTable(JSON.parse(item.table));
      this.setRecord(JSON.parse(item.record));
      this.setReportId(item.id);
      this.dialogVisibles = false;
    },

    // 新增志愿表
    setNew() {
      if (this.newName == "") {
        this.$message("请填写志愿表名称");
      }
      this.setTableName(this.newName);
      this.newName = null;

      //删除表ID
      localStorage.removeItem("report_id");
      this.setReportId(null);
      //批次 重新生成
      this.RefreshRecord();
      this.setTableReportData();
      this.$forceUpdate();
      this.newTableDialog = false;
    },

    //复制志愿表
    copy() {
      this.setReportId(null)
      this.copyDialog = false;
    },

    downReport() {
      console.log(this.table)
      console.log(this.userInfo)

      this.$router.push({
        path: "/PdfVolunteer",

        query: { table:  this.reportTable, userInfo: this.userInfo }
      })
    },



    moveEidt(item) {
      item.visibless = !item.visibless;
      this.$forceUpdate()
    },

    move(item, i, num, key) {
      item.visibless = false;

      if (i - 1 == key) {
        return false;
      }

      //判断移动的位置是否被填报过 有的话删除
      let index = this.record.dg.indexOf(num);
      if (index > -1) {
        this.record.id.splice(index, 1);
        this.record.dg.splice(index, 1);
        this.record.sp.splice(index, 1);
      }

      //找到位置。修改志愿位置
      let te = this.record.id.indexOf(item.select_code);
      if (te > -1) {
        this.record.dg[te] = num;
      }

      this.reportTable[i - 1] = this.reportTable[key];
      this.reportTable[key] = {
        name: "暂无",
        specialty: [],
      };
      this.setRecord(this.record)
      this.setReportTable(this.reportTable)
      this.$forceUpdate();

      //先查询位置
    },


    delCollege(item, i) {
      console.log(item, i)
      let index = this.record.id.indexOf(item.select_code);
      if (index > -1) {
        this.record.id.splice(index, 1);
        this.record.sp.splice(index, 1);
        this.record.dg.splice(index, 1);
      }

      this.reportTable[i] = {
        name: "暂无",
        specialty: [],
      };

      this.setRecord(this.record)
      this.setReportTable(this.reportTable)
      this.$forceUpdate();
    },


    //查看专业
    getSpecialty(item) {
      this.collegeData = item;
      this.dialogVisible = true;
    },

    //专业选择后的回调
    RefreshTable() {
      setTimeout(() => {
        this.dialogVisible = false
      }, 500)
    },

    closeDiaLog() {

    },

    toCollegeFirst() {
      this.addDialog = true;
      // this.$router.push("/volunteer/CollegeFirst");
    },
  },
};
</script>

<style lang="less">
.toobDiv {

  .el-button--mini,
  .el-button--small {
    font-size: 16px;
  }

  .el-button--mini,
  .el-button--mini.is-round {
    padding: 4px 4px;
  }
}

.dialogName {
  .content {
    padding: 40px 100px;
    visibility: middle;
    border-bottom: #ddd solid 1px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  .el-dialog__header {
    border-bottom: #ddd solid 1px;
  }

  .el-dialog__body {
    padding: 0;
    font-size: 16px;
  }
}
</style>


<style scoped lang='less'>
.toolbar {
  background: #f4f7fc;
  padding: 20px 40px;

  .toobDiv {
    .margin_right {
      margin-left: 3px;
    }
  }
}

.table {
  table {
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;

    .th {
      font-size: 16px;
      background: #f4f7fc;
      line-height: 35px;

      .name {
        font-size: 18px;
        font-weight: bold;
      }

      span {
        margin-right: 15px;
      }

      .col {
        margin-left: 0;
        color: #459df6;
      }
    }

    .th,
    td {
      line-height: 35px;
      padding: 5px 13px;
    }
  }
}

.px {
  margin-top: 20px;
  text-align: center;
  font-size: 14;
  margin-bottom: 10px;
}

.fc {
  text-align: center;
  color: #459df6;
  margin-bottom: 10px;
}

.hw,
.edit,
.del {
  text-align: center;
  margin-bottom: 10px;
}

.popover-f {
  width: 70px;
  background-color: #3d3d3c;
  color: white;
  min-width: 0;

  .popper__arrow {
    display: none;
  }

  .box-title {
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }

  .box-item {
    padding: 10px 0;
    font-size: 14px;
  }
}

.choseType {
  color: black;
}

.choseType:hover {
  color: white;

  p {
    background: #459df6;
  }
}
</style>
